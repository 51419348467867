.custom-pl {
  @apply pl-5 md:pl-8 lg:pl-12 xl:pl-24 2xl:pl-48;
}

.custom-px {
  @apply px-5 md:px-8 lg:px-12 xl:px-24 2xl:px-48;
}

.custom-plx {
  @apply px-5 md:px-8 lg:pl-12 xl:pl-24 2xl:pl-48;
}

.skeleton {
  @apply rounded bg-gradient-to-r;
  background-size: 400% 100%;
  animation: skeleton-loading 8s ease-in-out infinite;
}

.skeleton-gray {
  @apply rounded bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200;
}

@keyframes skeleton-loading {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}

.text-rtl {
  direction: rtl;
}

.bg-gradient-grey {
  background: linear-gradient(
    0.15deg,
    rgba(61, 61, 61, 0) 19.25%,
    rgba(61, 61, 61, 0.5) 99.82%
  );
}

.payment-info img {
  margin-top: 8px;
}

@screen lg {
  .keen-slider {
    @apply !select-auto;
  }
}
.colored-scrollbar::-webkit-scrollbar {
  @apply w-1 h-1;
}

.colored-scrollbar::-webkit-scrollbar-track {
  @apply bg-gray-50;
}

.colored-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-lightYellow rounded;
}

.thin-scrollbar::-webkit-scrollbar {
  @apply w-2 h-2;
}

.thin-scrollbar::-webkit-scrollbar-track {
  @apply bg-gray-50;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-gray-200 border-2 border-solid border-gray-50 rounded;
}

@media (max-width: 767px) {
  .omit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .omit-scrollbar::-webkit-scrollbar {
    display: none;
  }
}
